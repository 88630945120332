import type { ThunkAction } from 'redux-thunk';
import type { AnyAction } from 'redux';

import {
  EXIT_SEARCH_REVIEWS,
  HIDE_REVIEW_GALLERY_MODAL,
  RECEIVE_PRODUCT_REVIEWS,
  RECEIVE_PRODUCT_REVIEWS_WITH_MEDIA,
  RECEIVE_SEARCH_REVIEWS,
  RECEIVE_UPVOTE_REVIEW_FAILURE,
  RECEIVE_UPVOTE_REVIEW_SUCCESS,
  REQUEST_PRODUCT_REVIEWS,
  REQUEST_PRODUCT_REVIEWS_WITH_MEDIA,
  REQUEST_SEARCH_REVIEWS,
  REQUEST_UPVOTE_REVIEW,
  SET_DOC_META_PRODUCT_REVIEWS,
  SHOW_REVIEW_GALLERY_MODAL
} from 'constants/reduxActions';
import { getReviews } from 'apis/reviewsDisplay';
import { fetchProductDetail } from 'actions/productDetail';
import { IS_NUMBER_RE } from 'common/regex';
import { err, redirectToAuthWithHistory, setError } from 'actions/errors';
import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { trackError } from 'helpers/ErrorUtils';
import type { FormattedProductBundle } from 'reducers/detail/productDetail';
import type { AppState } from 'types/app';
import type { Review, V2ReviewsResponse } from 'types/reviewsDisplay';
import { MOST_HELPFUL, NEWEST } from 'constants/appConstants';
import { upvoteReview as upvoteReviewApi } from 'apis/cloudreviews';
import { setFederatedLoginModalVisibility } from 'actions/headerfooter';
import { getMafiaAndCredentials } from 'store/ducks/readFromStore';
import marketplace from 'cfg/marketplace.json';
import { selectMafiaConfig } from 'selectors/environment';

const { hasFederatedLogin } = marketplace;

const REVIEW_SORTS: Record<any, string> = {
  [MOST_HELPFUL]: 'upVotes:desc,overallRating:desc,reviewDate:desc',
  [NEWEST]: 'reviewDate:desc'
};

const reviewSort = (label: string) => REVIEW_SORTS[label] || REVIEW_SORTS[MOST_HELPFUL];

export function showReviewGalleryModal(reviewId: string, reviewMediaIndex: number) {
  return {
    type: SHOW_REVIEW_GALLERY_MODAL,
    reviewId,
    reviewMediaIndex
  } as const;
}

export function hideReviewGalleryModal() {
  return {
    type: HIDE_REVIEW_GALLERY_MODAL
  } as const;
}

export function requestProductReviewsWithMedia() {
  return {
    type: REQUEST_PRODUCT_REVIEWS_WITH_MEDIA
  } as const;
}

export function receiveProductReviewsWithMedia(reviews: Review[]) {
  return {
    type: RECEIVE_PRODUCT_REVIEWS_WITH_MEDIA,
    reviews
  } as const;
}

export function setProductReviewsDocMeta(product: FormattedProductBundle) {
  return { type: SET_DOC_META_PRODUCT_REVIEWS, product } as const;
}

export function requestSearchReviews(reviewsPage: string, searchTerm: string) {
  return {
    type: REQUEST_SEARCH_REVIEWS,
    reviewsPage,
    searchTerm
  } as const;
}

export function receiveSearchReviews(searchResults: V2ReviewsResponse) {
  return {
    type: RECEIVE_SEARCH_REVIEWS,
    searchResults
  } as const;
}

export function exitSearchReviews() {
  return {
    type: EXIT_SEARCH_REVIEWS
  } as const;
}

function requestProductReviews(reviewsPage: number | string, reviewsOffset?: number | string, orderBy?: string) {
  return {
    type: REQUEST_PRODUCT_REVIEWS,
    requestedAt: Date.now(),
    reviewsPage,
    reviewsOffset,
    orderBy
  } as const;
}

function receiveProductReviews(productId: string, reviewData: V2ReviewsResponse) {
  return {
    type: RECEIVE_PRODUCT_REVIEWS,
    receivedAt: Date.now(),
    productId,
    reviewData
  } as const;
}

export function requestUpvoteReview(reviewId: string) {
  return {
    type: REQUEST_UPVOTE_REVIEW,
    reviewId
  } as const;
}

export function receiveUpvoteReviewSucess(reviewId: string) {
  return {
    type: RECEIVE_UPVOTE_REVIEW_SUCCESS,
    reviewId
  } as const;
}

export function receiveUpvoteReviewFailure(reviewId: string) {
  return {
    type: RECEIVE_UPVOTE_REVIEW_FAILURE,
    reviewId
  } as const;
}

export function loadProductReviewsPage(
  productId: string,
  reviewsPage?: number | string,
  reviewsStart?: number | string,
  orderBy?: string
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return function (dispatch, getState) {
    return Promise.all([
      dispatch(fetchProductDetail(productId, { errorOnOos: false })),
      dispatch(fetchProductReviews(productId, reviewsPage, reviewsStart, true, orderBy)),
      dispatch(fetchProductReviewsWithMedia(productId))
    ]).then(() => {
      const {
        product: { detail }
      } = getState();
      detail && dispatch(setProductReviewsDocMeta(detail));
    });
  };
}

export function fetchProductReviewsWithMedia(
  productId: string,
  isCrucial = false,
  limit?: string
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return function (dispatch, getState) {
    dispatch(requestProductReviewsWithMedia());
    const state = getState();
    const url = getReviewDisplayUrl(state);
    return getReviews(url, { productId, limit, filter: 'hasMedia:true' })
      .then(fetchErrorMiddleware)
      .then(({ reviews }) => {
        dispatch(receiveProductReviewsWithMedia(reviews));
      })
      .catch(e => {
        if (isCrucial) {
          dispatch(setError(err.PRODUCT_DETAILS, e));
        } else {
          trackError('NON-FATAL', 'Could not load Product Reviews.', e);
        }
      });
  };
}

/**
 * Sets the current search result review page and offset in the store, as well as loads
 */
export function fetchReviewSearchResults(
  productId: string,
  query: string,
  page = '1',
  offset = '0',
  isCrucial = true
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return function (dispatch, getState) {
    dispatch(requestSearchReviews(page, query));
    const state = getState();
    const url = getReviewDisplayUrl(state);
    return getReviews(url, { productId, query, page, offset })
      .then(fetchErrorMiddleware)
      .then(reviewResponse => {
        dispatch(receiveSearchReviews(reviewResponse));
      })
      .catch(e => {
        if (isCrucial) {
          dispatch(setError(err.PRODUCT_DETAILS, e));
        } else {
          trackError('NON-FATAL', 'Could not load Product Review search results', e);
        }
      });
  };
}

/**
 * Sets the current review page and offset in the store, as well as loads
 * @param  {String}  productId        Product to load reviews for
 * @param  {Number}  [page=1]         what page of reviews to load
 * @param  {Number}  [offset=0]       how many reviews to load
 * @param  {Boolean} [isCrucial=true] whether a failed request should set the error state or simply log the error.  Defaults to showing the error page if an error is encountered.
 * @param {String} orderBy            sort reviews by (best aka most helpful or newest)
 * @return {Promise}                  fetch promise for loading reviews
 */
export function fetchProductReviews(
  productId: string,
  page: number | string = 1,
  offset: number | string = 0,
  isCrucial: boolean = true,
  orderBy: string = MOST_HELPFUL
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    // only sort options are most helpful and newest, so if it is not one of them, sanitize it.
    if (orderBy !== MOST_HELPFUL && orderBy !== NEWEST) {
      orderBy = MOST_HELPFUL;
    }
    dispatch(requestProductReviews(page, offset, orderBy));
    const state = getState();
    const url = getReviewDisplayUrl(state);
    return getReviews(url, {
      productId,
      page,
      offset,
      sort: reviewSort(orderBy)
    })
      .then(fetchErrorMiddleware)
      .then(reviewResponse => {
        dispatch(receiveProductReviews(productId, reviewResponse));
      })
      .catch(e => {
        if (isCrucial) {
          dispatch(setError(err.PRODUCT_DETAILS, e));
        } else {
          trackError('NON-FATAL', 'Could not load Product Reviews.', e);
        }
      });
  };
}

export function upvoteReview(reviewId: string, returnTo: string): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return function (dispatch, getState) {
    dispatch(requestUpvoteReview(reviewId));

    const state = getState();
    const credentials = getMafiaAndCredentials(state);
    const mafiaConfig = selectMafiaConfig(state);

    const upvoteFailure = (info?: string) => {
      dispatch(receiveUpvoteReviewFailure(reviewId));
      trackError('NON-FATAL', 'Could not upvote a product review.', info);
    };
    const upvoteApiCall = upvoteReviewApi(mafiaConfig.url, credentials, reviewId);
    return upvoteApiCall
      .then(response => {
        if (!response) {
          upvoteFailure();
        } else if (response.status === 200) {
          dispatch(receiveUpvoteReviewSucess(reviewId));
        } else if (response.status === 403) {
          if (hasFederatedLogin) {
            dispatch(setFederatedLoginModalVisibility(true, { returnTo }));
          } else {
            redirectToAuthWithHistory(dispatch, returnTo);
          }
        } else {
          upvoteFailure(`server responded with a status of ${response.status}`);
        }
      })
      .catch(upvoteFailure);
  };
}

export const getReviewDisplayUrl = (state: AppState) => {
  const {
    environmentConfig: {
      api: { reviewDisplay }
    }
  } = state;
  return reviewDisplay.url;
};

interface ReviewRequestOpts {
  productId: string;
  reviewsPage?: string;
  reviewsStart?: string;
}

export function isValidReviewsRequest({ productId, reviewsPage, reviewsStart }: ReviewRequestOpts) {
  // dont bother validating orderBy since review fetcher handles any input
  return (
    productId &&
    IS_NUMBER_RE.test(productId) &&
    (!reviewsPage || IS_NUMBER_RE.test(reviewsPage)) &&
    (!reviewsStart || IS_NUMBER_RE.test(reviewsStart))
  );
}

export type ReviewAction =
  | ReturnType<typeof showReviewGalleryModal>
  | ReturnType<typeof hideReviewGalleryModal>
  | ReturnType<typeof requestProductReviewsWithMedia>
  | ReturnType<typeof receiveProductReviewsWithMedia>
  | ReturnType<typeof setProductReviewsDocMeta>
  | ReturnType<typeof requestProductReviews>
  | ReturnType<typeof receiveProductReviews>
  | ReturnType<typeof requestSearchReviews>
  | ReturnType<typeof receiveSearchReviews>
  | ReturnType<typeof requestUpvoteReview>
  | ReturnType<typeof receiveUpvoteReviewSucess>
  | ReturnType<typeof receiveUpvoteReviewFailure>
  | ReturnType<typeof exitSearchReviews>;
