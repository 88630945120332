import React from 'react';
import { cn } from 'helpers/classnames';

import css from 'styles/components/icons/fitSurveyValue.scss';
interface Props {
  value: number;
  useDashes?: boolean;
}

const FitSurveyValue = ({value, useDashes}: Props) => (
  <div className={css.container}>
    {!useDashes && <div className={css.arm} />}
    <div className={css.chartContainer}>
      {[1, 2, 3, 4, 5].map(i => (
        <span key={i} className={cn(useDashes ? css.dash : css.bubble, { [css.selected]: i === value })} />
      ))}
    </div>
  </div>
);

export default FitSurveyValue;
