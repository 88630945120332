import React from 'react';

import MartyLink from 'components/common/MartyLink';
import useMartyContext from 'hooks/useMartyContext';
import { track } from 'apis/amethyst';
import { evWriteProductReviewClick } from 'events/productReview';
import { PRODUCT_PAGE } from 'constants/amethystPageTypes';

import css from 'styles/components/reviews/addReviewButton.scss';

interface AddReviewButtonProps {
  productId: string | undefined;
  colorId: string | undefined;
}

const AddReviewButton = ({ productId, colorId }: AddReviewButtonProps) => {
  const { testId } = useMartyContext();

  const handleWriteReviewClick = () => {
    track(() => [evWriteProductReviewClick, { productId, colorId, addedFrom: PRODUCT_PAGE }]);
  };

  return (
    <MartyLink
      className={css.link}
      to={`/product/review/add/${productId}${colorId ? `/color/${colorId}` : ''}`}
      onClick={handleWriteReviewClick}
      data-test-id={testId('writeAReviewButton')}
    >
      Write a review
    </MartyLink>
  );
};

export default AddReviewButton;
