import React from 'react';

import FitSurveyValue from 'components/icons/FitSurveyValue';

import css from 'styles/components/reviews/fitSurvey.scss';

const makeDimension = (rating: number, leftLabel: string, rightLabel: string, isFullMaxWidth: boolean) => (
  <div className={css.dimension}>
    <div className={css.dimensionLabels}>
      <span>{leftLabel}</span>
      <span>{rightLabel}</span>
    </div>
    <FitSurveyValue value={rating} useDashes={isFullMaxWidth} />
  </div>
);

interface Props {
  archRating?: number | null;
  sizeRating?: number | null;
  widthRating?: number | null;
  isFullMaxWidth?: boolean;
}

const FitSurvey = (props: Props) => {
  const { archRating, sizeRating, widthRating, isFullMaxWidth } = props;
  const hasRatings = [archRating, sizeRating, widthRating].some(Boolean);
  return hasRatings ? (
    <div className={css.container}>
      {sizeRating && makeDimension(sizeRating, 'Runs Small', 'Runs Large', !!isFullMaxWidth)}
      {widthRating && makeDimension(widthRating, 'Runs Narrow', 'Runs Wide', !!isFullMaxWidth)}
      {archRating && makeDimension(archRating, 'Poor Support', 'Great Support', !!isFullMaxWidth)}
    </div>
  ) : null;
};

export default FitSurvey;
