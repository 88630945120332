import React from 'react';

import { cn } from 'helpers/classnames';
import BuyBoxReviewBlurb from 'components/productdetail/BuyBoxReviewBlurb';
import AddReviewButton from 'components/reviews/AddReviewButton';
import Rating from 'components/Rating';
import { pluralize } from 'helpers/index';
import { toThousandsSeparator } from 'helpers/NumberFormats';
import useMartyContext from 'hooks/useMartyContext';
import type { ProductDetailState } from 'reducers/detail/productDetail';
import type { ProductFit, ProductReviewSummary } from 'types/cloudCatalog';
import { evProductOverallReviewImpression } from 'events/productReview';
import ProductUtils from 'helpers/ProductUtils';
import AmethystViewableImpression from 'components/common/AmethystViewableImpression';

import css from 'styles/components/productdetail/reviewSummary.scss';

interface Props {
  product: ProductDetailState;
  isFullWidth?: boolean;
}

interface StructuredData {
  itemProp?: string;
  itemScope?: boolean;
  itemType?: string;
}

export function hasPercentage(value: ProductFit | null | undefined): boolean {
  return !!value?.percentage;
}

export function atLeastOneRatingHasPercentage(reviewSummary: ProductReviewSummary) {
  const { maxSizeRatingPercentage, maxWidthRatingPercentage, maxArchRatingPercentage } = reviewSummary;
  return (
    reviewSummary && (hasPercentage(maxSizeRatingPercentage) || hasPercentage(maxWidthRatingPercentage) || hasPercentage(maxArchRatingPercentage))
  );
}

export function makeTotalText(props: Props, testId: (id: string) => string | undefined) {
  const { reviewCount, reviewSummary } = props.product.detail!;
  const { reviewingAShoe } = reviewSummary;

  const className = cn(css.reviewsTotalCount, {
    [css.reviewNonShoe]: !reviewingAShoe || !atLeastOneRatingHasPercentage(reviewSummary)
  });
  const formattedReviewCount = toThousandsSeparator(reviewCount);
  const formattedReviewCountLabel = pluralize('review', +reviewCount);
  return (
    <div className={className} data-test-id={testId('totalReviewCount')}>
      <div itemProp="reviewCount" className="hidden">
        {reviewCount}
      </div>{' '}
      <span>{formattedReviewCount}</span> total {formattedReviewCountLabel}
    </div>
  );
}

export default function ReviewSummary({ product, isFullWidth }: Props) {
  const { detail, styleThumbnails, colorId, reviewData: { productId } = {} } = product;
  const style = detail && ProductUtils.getStyleByColor(detail.styles, colorId);

  const { testId } = useMartyContext();

  if (!detail) {
    return null;
  }

  const { reviewCount, reviewSummary, productRating } = detail;
  const { aggregateRating, maxSizeRatingPercentage, maxWidthRatingPercentage, maxArchRatingPercentage, overallRating, reviewingAShoe } =
    reviewSummary;

  if (!reviewCount) {
    return null;
  }

  const reviewActionsClassName = cn(css.reviewActions, {
    [css.reviewNonShoe]: !reviewingAShoe || !atLeastOneRatingHasPercentage(reviewSummary)
  });
  const reviewRatingSummaryClassName = cn(css.reviewRatingSummary, {
    [css.reviewNonShoe]: !reviewingAShoe || !atLeastOneRatingHasPercentage(reviewSummary)
  });
  const possibleStarRatings = [5, 4, 3, 2, 1] as const;
  const useStructuredData = +reviewCount > 0;

  const ratingStructuredData: StructuredData = {};

  if (useStructuredData) {
    ratingStructuredData.itemProp = 'aggregateRating';
    ratingStructuredData.itemScope = true;
    ratingStructuredData.itemType = 'https://schema.org/AggregateRating';
  }

  return (
    <AmethystViewableImpression event={evProductOverallReviewImpression} productId={productId} styleId={style?.styleId}>
      <div className={css.reviewSummaryOuter}>
        <div className={css.reviewSummaryInner}>
          <div
            data-test-id={testId('reviewSummaryAndBreakdown')}
            className={cn(css.productReviewBreakdown, { [css.fullWidth]: isFullWidth })}
            {...ratingStructuredData}
          >
            {!isFullWidth ? (
              <>
                <h2 className="sr-only">Rating Summmary:</h2>
                <div className={css.productReviewTotal}>
                  {makeTotalText({ product }, testId)}
                  <div className={reviewActionsClassName}>
                    {styleThumbnails && styleThumbnails.length > 0 && (
                      <img className={css.productImage} src={styleThumbnails[0]!.src} alt={`${detail.brandName} ${detail.productName}`} />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={css.overallRating}>
                  <h3 className={css.title}>
                    <b>Overall Rating</b>
                  </h3>
                  <BuyBoxReviewBlurb numReviews={reviewCount} rating={productRating} />
                  <AddReviewButton productId={productId} colorId={colorId} />
                </div>
                <div className={css.verticalLine} />
              </>
            )}
            {!isFullWidth ? (
              <div className={reviewRatingSummaryClassName}>
                <h3 className="sr-only">Review Breakdown:</h3>
                {overallRating &&
                  possibleStarRatings.map(num => (
                    <p key={num}>
                      <span>{overallRating[num] ? overallRating[num] : 0}%</span>
                      <Rating rating={num} />
                    </p>
                  ))}
                {useStructuredData && <meta itemProp="ratingValue" content={String(aggregateRating)} />}
              </div>
            ) : (
              <div className={css.ratingBreakdown}>
                <h3 className={css.title}>
                  <b>Rating Breakdown</b>
                </h3>
                {overallRating &&
                  possibleStarRatings.map(num => (
                    <p key={num}>
                      <Rating rating={num} isUseBarGraph={true} ratingPercent={overallRating[num] ? overallRating[num] : '0'} />
                      <span className={css.ratingPercentage}>{overallRating[num] ? overallRating[num] : 0}%</span>
                    </p>
                  ))}
                {useStructuredData && <meta itemProp="ratingValue" content={String(aggregateRating)} />}
              </div>
            )}
            {reviewingAShoe && atLeastOneRatingHasPercentage(reviewSummary) && !isFullWidth ? (
              <div className={css.reviewFitSummary}>
                <h3 className="sr-only">Customer Fit Survey:</h3>
                {hasPercentage(maxSizeRatingPercentage) ? (
                  <p>
                    <span>{maxSizeRatingPercentage?.percentage ? maxSizeRatingPercentage.percentage : 0}%</span>
                    <span>"{maxSizeRatingPercentage?.text.substring(0, 24)}"</span>
                  </p>
                ) : null}
                {hasPercentage(maxWidthRatingPercentage) ? (
                  <p>
                    <span>{maxWidthRatingPercentage?.percentage ? maxWidthRatingPercentage.percentage : 0}%</span>
                    <span>"{maxWidthRatingPercentage?.text}"</span>
                  </p>
                ) : null}
                {hasPercentage(maxArchRatingPercentage) ? (
                  <p>
                    <span>{maxArchRatingPercentage?.percentage ? maxArchRatingPercentage.percentage : 0}%</span>
                    <span>"{maxArchRatingPercentage?.text}"</span>
                  </p>
                ) : null}
              </div>
            ) : null}
            {reviewingAShoe && atLeastOneRatingHasPercentage(reviewSummary) && isFullWidth ? (
              <>
                <div className={css.verticalLine} />
                <div className={css.fitSurveyFullWidth}>
                  <h3 className={css.title}>
                    <b>Fit Survey</b>
                  </h3>
                  {hasPercentage(maxSizeRatingPercentage) ? (
                    <p>
                      <span className={css.secondaryText}>
                        {maxSizeRatingPercentage?.percentage ? maxSizeRatingPercentage.percentage : 0}% said this{' '}
                      </span>
                      <span className={css.percentageText}>"{maxSizeRatingPercentage?.text.substring(0, 24)}"</span>
                      <span
                        style={{ '--barPercentage': maxSizeRatingPercentage?.percentage + '%' } as React.CSSProperties}
                        className={css.barGraph}
                      />
                    </p>
                  ) : null}
                  {hasPercentage(maxWidthRatingPercentage) ? (
                    <p>
                      <span className={css.secondaryText}>
                        {maxWidthRatingPercentage?.percentage ? maxWidthRatingPercentage.percentage : 0}% said this{' '}
                      </span>
                      <span className={css.percentageText}>"{maxWidthRatingPercentage?.text}"</span>
                      <span
                        style={{ '--barPercentage': maxWidthRatingPercentage?.percentage + '%' } as React.CSSProperties}
                        className={css.barGraph}
                      />
                    </p>
                  ) : null}
                  {hasPercentage(maxArchRatingPercentage) ? (
                    <p>
                      <span className={css.secondaryText}>
                        {maxArchRatingPercentage?.percentage ? maxArchRatingPercentage.percentage : 0}% said this{' '}
                      </span>
                      <span className={css.percentageText}>"{maxArchRatingPercentage?.text}"</span>
                      <span
                        style={{ '--barPercentage': maxArchRatingPercentage?.percentage + '%' } as React.CSSProperties}
                        className={css.barGraph}
                      />
                    </p>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </AmethystViewableImpression>
  );
}
