import React from 'react';

import { cn } from 'helpers/classnames';
import { MOST_HELPFUL_REVIEWS_ID, REVIEWS_HEADING_ID } from 'components/productdetail/ReviewPreview';
import Rating from 'components/Rating';
import { toThousandsSeparator } from 'helpers/NumberFormats';
import useMartyContext from 'hooks/useMartyContext';

import styles from 'styles/components/productdetail/buyBoxReviewBlurb.scss';

type IntrinsicAnchorProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

interface ReviewWrapperProps {
  isLink: boolean;
  children: React.ReactNode;
  className: string;
  rest?: { [key: string]: Props & IntrinsicAnchorProps };
}

const ReviewWrapper: React.FC<ReviewWrapperProps> = ({ isLink, children, className, ...props }) =>
  isLink ? (
    <a className={className} {...props}>
      {children}
    </a>
  ) : (
    <div className={className}>{children}</div>
  );

interface Props {
  rating: string;
  numReviews: string;
  className?: string;
  hasMostHelpfulReviews?: boolean;
  isReviewClickable?: boolean;
}

const BuyBoxReviewBlurb = ({
  className,
  numReviews,
  rating,
  hasMostHelpfulReviews,
  isReviewClickable = true,
  ...rest
}: Props & IntrinsicAnchorProps) => {
  const { testId } = useMartyContext();
  const linkTarget = hasMostHelpfulReviews ? `#${MOST_HELPFUL_REVIEWS_ID}` : `#${REVIEWS_HEADING_ID}`;
  // `!(n > 0)` is stricter than `n <= 0` because `n > 0` only evaluates to
  // to true for numbers whereas `n <= 0 && n === null` can be true
  if (!(parseInt(numReviews) > 0)) {
    return null;
  }

  return (
    <ReviewWrapper
      isLink={isReviewClickable}
      className={cn(styles.container, className)}
      href={linkTarget}
      data-test-id={testId('productReviews')}
      {...rest}
    >
      <Rating additionalClasses={styles.ratingLayout} rating={rating} reviewCount={numReviews} />
      <span
        aria-label={`${numReviews} reviews`}
        className={isReviewClickable ? styles.ratingLabel : styles.nonInteractiveRatingLabel}
        data-test-id={testId('reviewCount')}
      >{`(${toThousandsSeparator(numReviews)})`}</span>
    </ReviewWrapper>
  );
};

export default BuyBoxReviewBlurb;
