import { stringify } from 'query-string';

import timedFetch from 'middleware/timedFetch';
import type { V2ReviewsResponse } from 'types/reviewsDisplay';

/**
 * Get product review for a product
 */

interface DisplayReviewsV2QueryParams {
  productId: string;
  limit?: string | number;
  filter?: string;
  offset?: string | number;
  page?: string | number;
  query?: string;
  sort?: string;
  types?: string;
}

export function getReviews(
  url: String,
  criteria: DisplayReviewsV2QueryParams,
  fetcher = timedFetch('review')
): Promise<Response<V2ReviewsResponse>> {
  return fetcher(`${url}/v2/reviews?${stringify(criteria)}`);
}
