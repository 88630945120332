import React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import ReviewSummary from 'components/productdetail/ReviewSummary';
import { fetchProductReviews, upvoteReview } from 'actions/reviews';
import HtmlToReact from 'components/common/HtmlToReact';
import { Loader } from 'components/Loader';
import MartyLink from 'components/common/MartyLink';
import Review from 'components/reviews/Review';
import MostHelpfulReviews from 'components/reviews/MostHelpfulReviews';
import ReviewSort from 'components/reviews/ReviewSort';
import { toThousandsSeparator } from 'helpers/NumberFormats';
import { buildSeoProductUrl } from 'helpers/SeoUrlBuilder';
import marketplace from 'cfg/marketplace.json';
import { pluralize } from 'helpers/index';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { track } from 'apis/amethyst';
import { evWriteProductReviewClick } from 'events/productReview';
import { PRODUCT_PAGE } from 'constants/amethystPageTypes';
import type { AppState } from 'types/app';
import { MartyContext } from 'utils/context';
import { shouldRenderMostHelpfulReviews } from 'helpers/ReviewUtils';

import css from 'styles/components/productdetail/reviewPreview.scss';

export const REVIEWS_HEADING_ID = 'customerReviews';
export const MOST_HELPFUL_REVIEWS_ID = 'mostHelpfulReviews';

interface Params {
  productId: string;
  colorId?: string;
  seoName?: string;
}

interface OwnProps {
  onReviewMediaClick: (reviewId: string, mediaIndex: number) => void;
  params: Params;
  isFullMaxWidth?: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = OwnProps & PropsFromRedux;
export const ReviewPreview = (props: Props) => {
  const getAddReviewUrl = ({ productId, colorId }: Params) => `/product/review/add/${productId}${colorId ? `/color/${colorId}` : ''}`;

  const handleReviewUpvoteClick = (reviewId: string) => {
    const {
      detail,
      params: { colorId },
      upvoteReview,
      reviewData: { loadingReviews }
    } = props;
    if (!loadingReviews.includes(reviewId)) {
      const returnTo = buildSeoProductUrl(detail, colorId);
      upvoteReview(reviewId, returnTo);
    }
  };

  const handleSortReviewsClick = (orderBy: string) => {
    const {
      fetchProductReviews,
      params: { productId },
      reviewData
    } = props;
    if (reviewData.orderBy === orderBy) {
      return;
    }
    fetchProductReviews(productId, 1, 0, false, orderBy);
  };

  const makeBrandProductName = () => {
    const { brandProductName } = props.detail || {};
    return <HtmlToReact>{brandProductName}</HtmlToReact>;
  };

  function makeProductReviewContainerUrl(productId: string, orderBy?: string) {
    return `/product/review/${productId}${orderBy ? `/page/1/orderBy/${orderBy}` : ''}`;
  }
  const makeReviewsHeading = () => (
    <>
      <h2 id={REVIEWS_HEADING_ID} className={css.reviewsHeading}>
        Customer {pluralize('Review', props.reviewData.reviews?.length)}
      </h2>
      <div className={cn(css.reviewsBrandProductName, { [css.fullMaxWidth]: props.isFullMaxWidth })}>{makeBrandProductName()}</div>
    </>
  );

  const makeReviewsList = (count: number) => {
    const {
      detail,
      reviewData: { reviews, submittedReviews, loadingReviews },
      onReviewMediaClick,
      isFullMaxWidth
    } = props;

    if (!reviews || !detail) {
      return null;
    }

    const { brandName, productName, description, defaultImageUrl } = detail;

    return reviews.slice(0, count).map((review, i) => (
      <div
        key={review.id}
        className={cn(css.reviewWrapper, {
          [css.lastReviewWrapper]: i === count - 1
        })}
      >
        <Review
          review={review}
          brandName={brandName}
          productName={productName}
          description={description?.bulletPoints?.[0] || ''}
          defaultImage={defaultImageUrl}
          loadingReviews={loadingReviews}
          submittedReviews={submittedReviews}
          onReviewUpvoteClick={handleReviewUpvoteClick}
          onReviewMediaClick={onReviewMediaClick}
          isFullMaxWidth={isFullMaxWidth}
        />
      </div>
    ));
  };

  const handleWriteReviewClick = () => {
    const {
      params: { productId, colorId }
    } = props;
    track(() => [evWriteProductReviewClick, { productId, colorId, addedFrom: PRODUCT_PAGE }]);
  };

  const {
    detail,
    product,
    reviewData: { reviews: productReviews, isLoading, loadingReviews, orderBy, submittedReviews },
    params: { productId },
    params,
    showReviews,
    onReviewMediaClick,
    isFullMaxWidth = false
  } = props;

  if (!showReviews || !detail) {
    return null;
  }

  const { reviewCount = 0, productName, reviewSummary } = detail;
  return (
    <MartyContext.Consumer>
      {({ testId }) => (
        <div className={cn(css.container, { [css.fullMaxWidth]: isFullMaxWidth })} data-test-id={testId('reviewContainer')}>
          {shouldRenderMostHelpfulReviews(reviewSummary) && (
            <div className={css.mostHelpfulReviewsHeadingWrapper}>
              <h2 id={MOST_HELPFUL_REVIEWS_ID} data-test-id={testId('mostHelpfulReviewsHeader')} className={css.mostHelpfulReviewsHeading}>
                What Customers Are Saying
              </h2>
              <div className={css.mostHelpfulReviewsBrandProductName}>{makeBrandProductName()}</div>
            </div>
          )}
          {productReviews && productReviews.length ? (
            <>
              <MostHelpfulReviews
                reviewSummary={reviewSummary}
                data-test-id={testId('helpfulReviews')}
                loadingReviews={loadingReviews}
                onReviewMediaClick={onReviewMediaClick}
                onReviewUpvoteClick={handleReviewUpvoteClick}
                productName={productName}
                submittedReviews={submittedReviews}
              />
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <div className={css.reviewsTitleContainer} data-test-id={testId('reviewsHeader')}>
                    {makeReviewsHeading()}
                    {!isFullMaxWidth && (
                      <div className={css.writeAReviewLink}>
                        <MartyLink to={getAddReviewUrl(params)} onClick={handleWriteReviewClick} data-test-id={testId('writeAReviewButton')}>
                          Write a review
                        </MartyLink>
                      </div>
                    )}
                    <ReviewSummary product={product} isFullWidth={isFullMaxWidth} />
                    <div data-test-id={testId('sortOptions')}>
                      <ReviewSort orderBy={orderBy} onSortReviewsClick={handleSortReviewsClick} />
                    </div>
                  </div>
                  {makeReviewsList(5)}
                  <div className={css.readAdditionalReviewsWrapper}>
                    {Number(reviewCount) > 5 && (
                      <MartyLink
                        to={makeProductReviewContainerUrl(productId, orderBy)}
                        className={css.readAdditionalReviews}
                        data-test-id={testId('readAdditionalReviews')}
                      >
                        <span>Read Additional {toThousandsSeparator(Number(reviewCount) - 5)} Customer Reviews</span>
                      </MartyLink>
                    )}
                  </div>
                </>
              )}
            </>
          ) : null}
          {productReviews && !productReviews.length ? (
            <div className={css.noReviewsContainer}>
              {makeReviewsHeading()}
              <p data-test-id={testId('zeroReviewsText')} className={css.noReviewsText}>
                This product currently has 0 reviews.
              </p>
              <MartyLink
                to={getAddReviewUrl(params)}
                className={css.writeAReviewLink}
                onClick={handleWriteReviewClick}
                data-test-id={testId('writeAReviewButton')}
              >
                Write a review
              </MartyLink>
            </div>
          ) : null}
        </div>
      )}
    </MartyContext.Consumer>
  );
};

function mapStateToProps(state: AppState) {
  const { product } = state;
  const { detail, reviewData } = product;
  const {
    features: { showReviews }
  } = marketplace;
  return {
    detail,
    product,
    showReviews,
    reviewData,
    returnTo: buildSeoProductUrl(detail)
  };
}

const mapDispatchToProps = {
  fetchProductReviews,
  upvoteReview
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const ConnectedReviewPreview = connector(ReviewPreview);
export default withErrorBoundary('ReviewPreview', ConnectedReviewPreview);
